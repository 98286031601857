body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.slide-over-root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  /* Align the SlideOver to the right */
  align-items: flex-start;
  /* Align items at the start (top) */
  transition:
    opacity 0.3s ease-in-out,
    visibility 0.3s ease-in-out;
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.slide-over-root.show {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.slide-over-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.4s ease-in-out;
}

.backdrop-blur {
  backdrop-filter: blur(4px);
}

.slide-over-panel {
  position: fixed;
  top: 53px;
  /* Adjust this value based on the height of your header */
  transition:
    transform 0.4s ease-in-out,
    opacity 0.4s ease-in-out;
  width: 90%;
  /* Use percentage for width */
  max-width: 90%;
  /* Use percentage for max-width */
  border-radius: 10px;
  height: calc(100% - 20px);
}

@media (min-width: 768px) {
  .slide-over-panel {
    width: 25%;
    /* Use percentage for width on larger screens */
    /* max-width: 25%;*/
    max-width: 50%;
    /* Use percentage for max-width on larger screens */
  }
}

.translate-x-full {
  transform: translateX(100%);
}

.translate-x-0 {
  transform: translateX(0);
}

.translate-y-full {
  transform: translateY(100%);
}

.translate-y-0 {
  transform: translateY(0);
}

.translate-x--full {
  transform: translateX(-100%);
}

.translate-y--full {
  transform: translateY(-100%);
}

.oeui-slideover-x {
  width: 100%;
  height: 100%;
}

.oeui-slideover-y {
  width: 100%;
  height: 100%;
}

.slide-over-content {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 100%;
}

.slide-over-header {
  display: flex;
  align-items: center;
  padding: 16px;
  background: #f1f1f1;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.slide-over-title {
  flex-grow: 1;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.slide-over-body {
  padding: 0px 6px;
  margin: 1px;
  overflow-y: auto;
  flex-grow: 1;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
